const sortOptionsLabels: Record<string, string> = {
  'sort:created-desc': 'Newest',
  'sort:created-asc': 'Oldest',
  'sort:comments-desc': 'Most commented',
  'sort:updated-desc': 'Recently updated',
  'sort:reactions-+1-desc': 'Thumbs up',
  'sort:reactions--1-desc': 'Thumbs down',
  'sort:reactions-rocket-desc': 'Rocket',
  'sort:reactions-tada-desc': 'Hooray',
  'sort:reactions-eyes-desc': 'Eyes',
  'sort:reactions-heart-desc': 'Heart',
}

export const LABELS = {
  numberOfResults: (numResults: number) => `${numResults} ${numResults === 1 ? 'result' : 'results'}`,
  documentTitleForView: (viewName?: string) => (viewName ? `${viewName} · Hyperlist` : `Hyperlist`),
  documentTitleForRepository: (repositoryOwner: string, repositoryName: string) =>
    `Issues · ${repositoryOwner}/${repositoryName}`,
  alpha: 'Alpha',
  beta: 'Beta',
  preview: 'Preview',
  allViews: 'All views',
  quickFilters: 'Quick filters',
  defaultViews: 'Default views',
  loadingListingResults: '.. results',
  loadingQueryResults: '.. loading results',
  issueSearchInputAriaLabel: 'Search Issues',
  sectionSearchInputAriaLabel: 'Search PRs',
  issueSearchInputPlaceholder: 'Search Issues',
  sectionSearchInputPlaceholder: 'Search Issues and PRs',
  viewSidebarPaneAriaLabel: 'Navigation sidebar',
  issueListPaneAriaLabel: 'Issue list',
  issueCreatePaneAriaLabel: 'Create issue pane',
  issueCreatePaneTitle: 'Create new issue',
  loadingTooltip: 'Loading...',
  myViews: 'My views',
  appHeader: 'Issues',
  savedViewsTitlePlaceholder: 'Add a title for your saved view',
  searchResults: 'Search results',
  selectedSubsetOfNumber: (selectedCount: number, totalCount: number) => `${selectedCount} of ${totalCount} selected`,
  singularUnits: (nodeTypes: Set<string | undefined>): string | undefined => {
    if (nodeTypes.has('Issue') && nodeTypes.has('PullRequest')) return 'issue or pull request'
    if (nodeTypes.has('Issue')) return 'issue'
    if (nodeTypes.has('PullRequest')) return 'pull request'
  },
  pluralUnits: (nodeTypes: Set<string | undefined>): string | undefined => {
    if (nodeTypes.has('Issue') && nodeTypes.has('PullRequest')) return 'issues and pull requests'
    if (nodeTypes.has('Issue')) return 'issues'
    if (nodeTypes.has('PullRequest')) return 'pull requests'
  },
  updatingIssues: 'Updating issues',
  viewsTitle: 'Views',
  views: {
    createLink: 'Create view',
    defaultName: 'Untitled view',
    edit: 'Edit',
    editButtonAriaLabel: 'Saved views menu',
    saveChangesToView: 'Save changes to view',
    createError: 'Could not create a new view',
    updateError: 'Could not update the view',
    iconAndColorAnchorAriaLabel: 'Change icon and color',
    deleteTitle: 'Delete view',
    deleteContent: (viewName: string) => `Are you sure you want to delete view ${viewName}?`,
    deleteConfirmationButton: 'Delete',
    deleteError: 'Could not delete view',
    duplicate: 'Duplicate to new view',
    delete: 'Delete',
    duplicateError: 'Could not duplicate view',
    editViewTitleAriaLabel: (shortcutName: string) => `Edit ${shortcutName} title`,
    editViewDescriptionAriaLabel: (shortcutName: string) => `Edit ${shortcutName} description`,
    editViewAriaLabel: (shortcutName: string) => `Edit ${shortcutName}`,
    maxViewsReached: 'You have reached the maximum number of saved views',
    teams: 'Teams',
    icon: 'Icon',
    color: 'Color',
    editSelectedTeams: 'Manage teams',
    manageTeams: 'Manage teams',
    noTeamsSelectedHeading: 'No teams selected.',
    noTeamsSelectedDescription: 'Choose teams that show up in your sidebar.',
    manageTeamsDescription: 'Choose teams that show up in your sidebar.',
    loadingSelectedTeams: 'Loading selected teams',
    addTeamsDesc: 'Add teams you are part of or want to follow along.',
    selectedTeams: 'Currently selected',
    unsavedChangesTitle: 'You have unsaved changes',
    unsavedChangesContent: 'Are you sure you want to discard them?',
  },
  filters: {
    author: 'Author',
    authorLabel: 'Filter by author',
    labels: 'Labels',
    labelsLabel: 'Filter by label',
    projects: 'Projects',
    projectsLabel: 'Filter by project',
    milestones: 'Milestones',
    milestonesLabel: 'Filter by milestone',
    assignees: 'Assignees',
    assigneesLabel: 'Filter by assignees',
    issueType: 'Types',
    issueTypeLabel: 'Filter by issue type',
  },
  sortBy: 'Sort by',
  sortByReactions: 'Sort by reactions',
  mostReactions: 'Most reactions',
  sortingLabels: sortOptionsLabels,
  Newest: 'Newest',
  Oldest: 'Oldest',
  RecentlyUpdated: 'Recently updated',
  Default: 'Default',
  Compact: 'Compact',
  Density: 'Density',
  bulkActions: 'Bulk actions',
  announcePage: (pageNumber: number, totalPages: number, items: number) =>
    `Page ${pageNumber} of ${totalPages} loaded with ${items} items`,
  sidePanelTooltip: 'View issue',
  viewProfile: (login: string) => `View ${login}'s profile`,
}
