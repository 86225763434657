import {DragAndDrop} from '@github-ui/drag-and-drop'
import {SafeHTMLBox} from '@github-ui/safe-html'
import {Box, Checkbox, Spinner} from '@primer/react'
import {useCallback, useState} from 'react'
import type {TaskItem} from '../constants/types'
import {handleItemToggle} from '../utils/handle-item-toggle'
import styles from './TaskListItem.module.css'
import {TaskListMenu} from './TaskListMenu'

export type TaskListItemProps = {
  markdownValue: string
  onChange: (markdown: string) => void | Promise<void>
  onConvertToIssue?: (task: TaskItem, setIsConverting: (converting: boolean) => void) => void
  onConvertToSubIssue?: (task: TaskItem, setIsConverting: (converting: boolean) => void) => void
  nested?: boolean
  position?: number
  item: TaskItem
  totalItems: number
  disabled?: boolean
  hideActions?: boolean
}

// Task list items that are already issues are wrapped in a `<span class="reference">
const IS_ISSUE_REGEX = /span class="reference"/

export function TaskListItem({
  markdownValue,
  onChange,
  onConvertToIssue,
  onConvertToSubIssue,
  nested = false,
  position = 1,
  item,
  totalItems,
  disabled,
  hideActions,
}: TaskListItemProps) {
  const [checked, setChecked] = useState(item.checked)
  const [showTrigger, setShowTrigger] = useState(false)
  const [isConverting, setIsConverting] = useState(false)

  const onToggleItem = useCallback(
    (markdown: string) => {
      setChecked(!item.checked)

      handleItemToggle({markdownValue: markdown, markdownIndex: item.markdownIndex, onChange})
    },
    [item, onChange],
  )

  const allowReordering =
    (!nested && !item.hasDifferentListTypes) ||
    (nested && position < 2 && !item.parentIsChecklist && !item.hasDifferentListTypes)

  const isIssue = IS_ISSUE_REGEX.test(item.content)
  const tasklistIstemTestIdBase = `tasklist-item-${position}-${item.markdownIndex}`

  let tasklistItemCssClasses =
    nested && (!onConvertToIssue || !onConvertToSubIssue)
      ? styles['no-convert-task-list-item']
      : styles['task-list-item']

  tasklistItemCssClasses += ` ${nested && totalItems > 0 ? 'contains-task-list' : ''}`
  tasklistItemCssClasses += disabled ? ` ${styles.disabled}` : ''

  return (
    <div
      className={tasklistItemCssClasses}
      onMouseEnter={() => setShowTrigger(true)}
      onMouseLeave={() => setShowTrigger(false)}
      data-testid={tasklistIstemTestIdBase}
    >
      <div className={styles['left-aligned-content']}>
        <div className={styles['drag-drop-container']}>
          {allowReordering && !disabled && (
            <DragAndDrop.DragTrigger
              className={`${styles['drag-handle-icon']} ${showTrigger ? styles['show-trigger'] : ''}`}
              style={{
                width: '20px',
                height: '28px',
              }}
            />
          )}
        </div>

        <div className={styles['checkbox-items']}>
          <Checkbox
            checked={checked}
            disabled={disabled}
            onChange={e => {
              e.preventDefault()
              e.stopPropagation()
              onToggleItem(markdownValue)
            }}
            aria-label={`${item.title} checklist item`}
            sx={{
              height: '1em',
              width: '1em',
              mt: '.5em',
              flexShrink: 0,
              ':before': {width: '1em'},
              ':disabled': {cursor: 'inherit'},
            }}
          />
          <SafeHTMLBox unverifiedHTML={item.content} className={styles['task-list-html']} />
        </div>
      </div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexShrink: 0,
          flexBasis: '28px',
          height: '28px',
        }}
      >
        {isConverting && <Spinner size="small" />}

        {!hideActions && allowReordering && item.position && !isConverting && (
          <TaskListMenu
            data-testid={`${tasklistIstemTestIdBase}-menu`}
            onConvertToIssue={onConvertToIssue}
            onConvertToSubIssue={onConvertToSubIssue}
            totalItems={totalItems}
            item={item}
            disabled={disabled}
            isIssue={isIssue}
            setIsConverting={setIsConverting}
            allowReordering={allowReordering}
          />
        )}
      </Box>
    </div>
  )
}

try{ TaskListItem.displayName ||= 'TaskListItem' } catch {}